// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "19.3.0",
  "buildNumber": "8959",
  "gitSha": "259d1f79e361875995072e5c1fcc9cbda7ccc600",
  "fullVersion": "19.3.0-8959",
  "formattedVersion": "19.3.0 (8959-259d1f79e361875995072e5c1fcc9cbda7ccc600)",
  "copyrightYear": "2019"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/19.3.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/19.3.0/${stripLeadingPrefix(suffix, '#')}`
}
    
