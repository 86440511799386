// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /admin/pipeline/new(.:format) */
export function pipelineNewPath(options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/:stage_parent/:pipeline_name/:current_tab(.:format) */
export function pipelineEditPath(stage_parent: ScalarType, pipeline_name: ScalarType, current_tab: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + stage_parent + "/" + pipeline_name + "/" + current_tab + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["stage_parent","pipeline_name","current_tab","format"]);
}

/** /admin/pipelines(.:format) */
export function pipelineGroupsPath(options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipelines" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/pipeline_group/new(.:format) */
export function pipelineGroupNewPath(options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/pipeline_group(.:format) */
export function pipelineGroupCreatePath(options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/pipeline_group/:group_name/edit(.:format) */
export function pipelineGroupEditPath(group_name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + "/" + group_name + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["group_name","format"]);
}

/** /admin/pipeline_group/:group_name(.:format) */
export function pipelineGroupShowPath(group_name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + "/" + group_name + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["group_name","format"]);
}

/** /admin/pipeline_group/:group_name(.:format) */
export function pipelineGroupUpdatePath(group_name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + "/" + group_name + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["group_name","format"]);
}

/** /admin/pipeline_group/:group_name(.:format) */
export function pipelineGroupDeletePath(group_name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + "/" + group_name + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["group_name","format"]);
}

/** /analytics(.:format) */
export function analyticsPath(options?: object): string {
  return "/go" + "/" + "analytics" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /analytics/:plugin_id/:type/:id(.:format) */
export function showAnalyticsPath(plugin_id: ScalarType, type: ScalarType, id: ScalarType, options?: object): string {
  return "/go" + "/" + "analytics" + "/" + plugin_id + "/" + type + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["plugin_id","type","id","format"]);
}

/** /environments(.:format) */
export function environmentRedirectPath(options?: object): string {
  return "/go" + "/" + "environments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/environments/create(.:format) */
export function environmentCreatePath(options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + "/" + "create" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/environments/new(.:format) */
export function environmentNewPath(options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/environments/:name(.:format) */
export function environmentUpdatePath(name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + "/" + name + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["name","format"]);
}

/** /admin/environments/:name/show(.:format) */
export function environmentShowPath(name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + "/" + name + "/" + "show" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["name","format"]);
}

/** /admin/environments/:name/edit/pipelines(.:format) */
export function environmentEditPipelinesPath(name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + "/" + name + "/" + "edit" + "/" + "pipelines" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["name","format"]);
}

/** /admin/environments/:name/edit/agents(.:format) */
export function environmentEditAgentsPath(name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + "/" + name + "/" + "edit" + "/" + "agents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["name","format"]);
}

/** /admin/environments/:name/edit/variables(.:format) */
export function environmentEditVariablesPath(name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + "/" + name + "/" + "edit" + "/" + "variables" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["name","format"]);
}

/** /admin/environments(.:format) */
export function environmentsPath(options?: object): string {
  return "/go" + "/" + "admin" + "/" + "environments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/current_user */
export function apiv1CurrentUserPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "current_user" + $buildOptions(options, []);
}

/** /api/notification_filters */
export function apiv1NotificationFiltersPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "notification_filters" + $buildOptions(options, []);
}

/** /api/notification_filters/:id */
export function apiv1NotificationFilterPath(id: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "notification_filters" + "/" + id + $buildOptions(options, ["id"]);
}

/** /api/users/:login_name */
export function apiv1UserPath(login_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "users" + "/" + login_name + $buildOptions(options, ["login_name"]);
}

/** /api/users */
export function apiv1UsersPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "users" + $buildOptions(options, []);
}

/** /api/admin/security/auth_configs */
export function apiv1AdminSecurityAuthConfigsPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "security" + "/" + "auth_configs" + $buildOptions(options, []);
}

/** /api/admin/security/auth_configs/:auth_config_id */
export function apiv1AdminSecurityAuthConfigPath(auth_config_id: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "security" + "/" + "auth_configs" + "/" + auth_config_id + $buildOptions(options, ["auth_config_id"]);
}

/** /api/admin/security/roles */
export function apiv1AdminSecurityRolesPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "security" + "/" + "roles" + $buildOptions(options, []);
}

/** /api/admin/security/roles/:role_name */
export function apiv1AdminSecurityRolePath(role_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "security" + "/" + "roles" + "/" + role_name + $buildOptions(options, ["role_name"]);
}

/** /api/admin/templates/:template_name/authorization */
export function apiv1AdminTemplatesPath(template_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "templates" + "/" + template_name + "/" + "authorization" + $buildOptions(options, ["template_name"]);
}

/** /api/admin/internal/security/auth_configs/verify_connection */
export function apiv1AdminInternalVerifyConnectionPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "security" + "/" + "auth_configs" + "/" + "verify_connection" + $buildOptions(options, []);
}

/** /api/admin/templates/:template_name */
export function apiv1AdminTemplatePath(template_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "templates" + "/" + template_name + $buildOptions(options, ["template_name"]);
}

/** /api/admin/environments/:environment_name/merged */
export function apiv1AdminMergedEnvironmentShowPath(environment_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "environments" + "/" + environment_name + "/" + "merged" + $buildOptions(options, ["environment_name"]);
}

/** /api/admin/environments/merged */
export function apiv1AdminMergedEnvironmentIndexPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "environments" + "/" + "merged" + $buildOptions(options, []);
}

/** /api/admin/repositories */
export function apiv1AdminRepositoriesPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "repositories" + $buildOptions(options, []);
}

/** /api/admin/repositories/:repo_id */
export function apiv1AdminRepositoryPath(repo_id: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "repositories" + "/" + repo_id + $buildOptions(options, ["repo_id"]);
}

/** /api/admin/plugin_settings */
export function apiv1AdminPluginSettingsPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "plugin_settings" + $buildOptions(options, []);
}

/** /api/admin/plugin_settings/:plugin_id */
export function apiv1AdminPluginSettingPath(plugin_id: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "plugin_settings" + "/" + plugin_id + $buildOptions(options, ["plugin_id"]);
}

/** /api/admin/packages */
export function apiv1AdminPackagesPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "packages" + $buildOptions(options, []);
}

/** /api/admin/packages/:package_id */
export function apiv1AdminPackagePath(package_id: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "packages" + "/" + package_id + $buildOptions(options, ["package_id"]);
}

/** /api/admin/internal/material_test */
export function apiv1AdminInternalMaterialTestPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "material_test" + $buildOptions(options, []);
}

/** /api/admin/internal/repository_check_connection */
export function apiv1AdminInternalRepositoryCheckConnectionPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "repository_check_connection" + $buildOptions(options, []);
}

/** /api/admin/internal/package_check_connection */
export function apiv1AdminInternalPackageCheckConnectionPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "package_check_connection" + $buildOptions(options, []);
}

/** /api/admin/internal/pipelines */
export function apiv1AdminInternalPipelinesPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "pipelines" + $buildOptions(options, []);
}

/** /api/admin/internal/resources */
export function apiv1AdminInternalResourcesPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "resources" + $buildOptions(options, []);
}

/** /api/admin/internal/environments */
export function apiv1AdminInternalEnvironmentsPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "environments" + $buildOptions(options, []);
}

/** /api/admin/internal/command_snippets */
export function apiv1AdminInternalCommandSnippetsPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "command_snippets" + $buildOptions(options, []);
}

/** /api/admin/scms */
export function apiv1AdminScmsPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "scms" + $buildOptions(options, []);
}

/** /api/admin/scms/:material_name */
export function apiv1AdminScmPath(material_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "scms" + "/" + material_name + $buildOptions(options, ["material_name"]);
}

/** /api/version_infos/stale */
export function apiv1StaleVersionInfoPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "version_infos" + "/" + "stale" + $buildOptions(options, []);
}

/** /api/version_infos/latest_version */
export function apiv1LatestVersionInfoPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "version_infos" + "/" + "latest_version" + $buildOptions(options, []);
}

/** /api/version_infos/go_server */
export function apiv1UpdateServerVersionInfoPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "version_infos" + "/" + "go_server" + $buildOptions(options, []);
}

/** /api/*url */
export function apiv1Path(url: ScalarType[], options?: object): string {
  return "/go" + "/" + "api" + "/" + url.map((part) => $encode(part)).join("/") + $buildOptions(options, ["url"]);
}

/** /api/admin/environments */
export function apiv2AdminEnvironmentsPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "environments" + $buildOptions(options, []);
}

/** /api/admin/environments/:name */
export function apiv2AdminEnvironmentPath(name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "environments" + "/" + name + $buildOptions(options, ["name"]);
}

/** /api/admin/environments/:name */
export function apiv2AdminPath(name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "environments" + "/" + name + $buildOptions(options, ["name"]);
}

/** /api/users */
export function apiv2UsersPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "users" + $buildOptions(options, []);
}

/** /api/users/:login_name */
export function apiv2UserPath(login_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "users" + "/" + login_name + $buildOptions(options, ["login_name"]);
}

/** /api/users/:login_name */
export function apiv2Path(login_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "users" + "/" + login_name + $buildOptions(options, ["login_name"]);
}

/** /api/admin/plugin_info */
export function apiv4AdminPluginInfoIndexPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "plugin_info" + $buildOptions(options, []);
}

/** /api/admin/plugin_info/:id */
export function apiv4AdminPluginInfoPath(id: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "plugin_info" + "/" + id + $buildOptions(options, ["id"]);
}

/** /api/*url */
export function apiv4Path(url: ScalarType[], options?: object): string {
  return "/go" + "/" + "api" + "/" + url.map((part) => $encode(part)).join("/") + $buildOptions(options, ["url"]);
}

/** /admin/status_reports/:plugin_id */
export function adminStatusReportPath(plugin_id: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "status_reports" + "/" + plugin_id + $buildOptions(options, ["plugin_id"]);
}

/** /admin/status_reports/:plugin_id/cluster/:cluster_profile_id */
export function adminClusterStatusReportPath(plugin_id: ScalarType, cluster_profile_id: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "status_reports" + "/" + plugin_id + "/" + "cluster" + "/" + cluster_profile_id + $buildOptions(options, ["plugin_id","cluster_profile_id"]);
}

/** /api/config/pipeline_groups(.:format) */
export function pipelineGroupConfigListApiPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "config" + "/" + "pipeline_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter(.:format) */
export function stageDetailTabDefaultPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/overview(.:format) */
export function stageDetailTabOverviewPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "overview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/pipeline(.:format) */
export function stageDetailTabPipelinePath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "pipeline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/materials(.:format) */
export function stageDetailTabMaterialsPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "materials" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/jobs(.:format) */
export function stageDetailTabJobsPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/stats(.:format) */
export function stageDetailTabStatsPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/stage_config(.:format) */
export function stageDetailTabStageConfigPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "stage_config" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /internal/pipelines/:pipeline_name/:pipeline_counter(.:format) */
export function internalStageDetailTabPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "internal" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","format"]);
}
